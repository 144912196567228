import { call, put, takeLatest, takeEvery, debounce, takeLeading, all } from 'redux-saga/effects';
import axios from 'axios';

import { actionTypes as listsActionType } from '../reducers';
import { isFunc } from '../../../utils/functions';
import config from '../../../config';

const usdaKey = config.USDA_API_KEY;

const Api = {
  createList: (listData) => axios.post('/lists', listData),
  getList: (id) => axios.get(`/lists/${id}`),
  getListWithRecipes: (id) => axios.get(`/lists/${id}/recipes`),
  getLists: () => axios.get('/lists'),
  updateList: ({ listId, listData }) => axios.patch(`/lists/${listId}`, listData),
  deleteList: (id) => axios.delete(`/lists/${id}`),
  getCategories: () => axios.get('/lists/categories'),
  getSuggestedItems: (query) => axios.get(`/lists/suggested-items${query && query.trim() !== '' ? `?query=${query}` : ''}`),
  getSuggestedItemsExternal:
    (query) => axios.get(`/lists/suggested-items/external${query && query.trim() !== '' ? `?query=${query}` : ''}`),
  getSuggestedUnits:
    (query) => axios.get(`/lists/suggested-units${query && query.trim() !== '' ? `?query=${query}` : '?limit=15'}`),
  addItemToList: ({ listId, itemData }) => axios.post(`/lists/${listId}/item`, itemData),
  removeItemFromList: ({ listId, itemId }) => axios.delete(`/lists/${listId}/item/${itemId}`),
  updateItemInList: ({ listId, itemData }) => axios.put(`/lists/${listId}/item/${itemData._id}`, itemData),
  restoreItemsInList: (listId) => axios.patch(`/lists/${listId}/restore-items`),
  shareList: (inviteData) => axios.post('/invite', inviteData),
  acceptList: (listId) => axios.get(`/lists/${listId}/accept`),
  declineList: (listId) => axios.get(`/lists/${listId}/decline`),
  findUSDAProducts: (itemName) => axios.get(
    `https://api.nal.usda.gov/fdc/v1/foods/search?api_key=${usdaKey}&query=${
      itemName.replace(/\//g, ' ')
    }&pageSize=1&sortBy=score&sortOrder=desc`
  ),
  getUSDAProduct: (productId) => axios.get(`https://api.nal.usda.gov/fdc/v1/food/${productId}?api_key=${usdaKey}`),
  addSuggestedItem: (itemData) => axios.post('/lists/suggested-items', itemData),
  getCollaborators: (listId) => axios.get(`/lists/${listId}/collaborators`),
  parseIngredients: (ingredients) => axios.post('/lists/parse-ingredients', { ingredients }),
  getFavoriteItems: (query) => axios.get(`/lists/favorite-items${query && query.trim() !== '' ? `?query=${query}` : ''}`),
  addFavoriteItems: (items) => axios.put('/lists/favorite-items', { items }),
  removeFavoriteItems: (items) => axios.patch('/lists/favorite-items/remove', { items })
};

export const actionTypes = {
  CREATE_LIST: 'CREATE_LIST',
  GET_LIST: 'GET_LIST',
  GET_LIST_WITH_RECIPES: 'GET_LIST_WITH_RECIPES',
  GET_LISTS: 'GET_LISTS',
  UPDATE_LIST: 'UPDATE_LIST',
  DELETE_LIST: 'DELETE_LIST',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_SUGGESTED_ITEMS: 'GET_SUGGESTED_ITEMS',
  GET_SUGGESTED_ITEMS_FOR_ARRAY: 'GET_SUGGESTED_ITEMS_FOR_ARRAY',
  GET_SUGGESTED_UNITS: 'GET_SUGGESTED_UNITS',
  ADD_ITEM_TO_LIST: 'ADD_ITEM_TO_LIST',
  REMOVE_ITEM_FROM_LIST: 'REMOVE_ITEM_FROM_LIST',
  UPDATE_ITEM_IN_LIST: 'UPDATE_ITEM_IN_LIST',
  RESTORE_ITEMS_IN_LIST: 'RESTORE_ITEMS_IN_LIST',
  SHARE_LIST: 'SHARE_LIST',
  ACCEPT_LIST: 'ACCEPT_LIST',
  DECLINE_LIST: 'DECLINE_LIST',
  FIND_USDA_PRODUCTS: 'FIND_USDA_PRODUCTS',
  GET_USDA_PRODUCT: 'GET_USDA_PRODUCT',
  ADD_SUGGESTED_ITEM: 'ADD_SUGGESTED_ITEM',
  GET_COLLABORATORS: 'GET_COLLABORATORS',
  PARSE_INGREDIENTS: 'PARSE_INGREDIENTS',
  PARSE_INGREDIENTS_WITH_DEBOUNCE: 'PARSE_INGREDIENTS_WITH_DEBOUNCE',
  GET_FAVORITE_ITEMS: 'GET_FAVORITE_ITEMS',
  ADD_FAVORITE_ITEMS: 'ADD_FAVORITE_ITEMS',
  REMOVE_FAVORITE_ITEMS: 'REMOVE_FAVORITE_ITEMS'
};

function* sagaCreateList({ payload: { listData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.createList, listData);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetList({ payload: { id, isSetActive = true, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getList, id);
    if (isSetActive) yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetListWithRecipes({ payload: { id, isSetActive = true, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getListWithRecipes, id);
    if (isSetActive) yield put({ type: listsActionType.ACTIVE_LIST, payload: data.list });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetLists({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getLists);
    // AVAILABLE_LISTS and SHARED_LISTS should be updated in onSuccess
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteList({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.deleteList, id);
    // AVAILABLE_LISTS and SHARED_LISTS should be updated in onSuccess
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateList({ payload: { listId, listData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.updateList, { listId, listData });
    if (data?._id) yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAddItemToList({ payload: { listId, itemData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.addItemToList, { listId, itemData });
    yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateItemInList({ payload: { listId, itemData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.updateItemInList, { listId, itemData });
    yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRestoreItemsInList({ payload: { listId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.restoreItemsInList, listId);
    yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRemoveItemFromList({ payload: { listId, itemId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.removeItemFromList, { listId, itemId });
    yield put({ type: listsActionType.ACTIVE_LIST, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCategories({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getCategories);
    yield put({ type: listsActionType.CATEGORIES, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSuggestedItems({ payload: { query, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getSuggestedItems, query);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSuggestedItemsForArray({ payload: { names, onSuccess, onError } }) {
  try {
    const requests = names.map((name) => call(Api.getSuggestedItemsExternal, name));
    const responses = yield all(requests);
    const data = responses.map((response) => response.data);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSuggestedUnits({ payload: { query, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getSuggestedUnits, query);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaShareList({ payload: { inviteData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.shareList, inviteData);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAcceptList({ payload: { listId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.acceptList, listId);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeclineList({ payload: { listId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.declineList, listId);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaFindUSDAProducts({ payload: { itemName, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.findUSDAProducts, itemName);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetUSDAProduct({ payload: { productId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getUSDAProduct, productId);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAddSuggestedItem({ payload: { itemData, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.addSuggestedItem, itemData);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCollaborators({ payload: { listId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getCollaborators, listId);
    yield put({ type: listsActionType.COLLABORATORS, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaParseIngredients({ payload: { ingredients, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.parseIngredients, ingredients);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaParseIngredientsDebounce({ payload: { ingredients, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.parseIngredients, ingredients);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetFavoriteItems({ payload: { query, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getFavoriteItems, query);
    yield put({ type: listsActionType.FAVORITE_ITEMS, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAddFavoriteItems({ payload: { items, onSuccess, onError } }) {
  try {
    yield call(Api.addFavoriteItems, items);
    // call getFavoriteItems on success
    if (isFunc(onSuccess)) onSuccess();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRemoveFavoriteItems({ payload: { items, onSuccess, onError } }) {
  try {
    yield call(Api.removeFavoriteItems, items);
    // call getFavoriteItems on success
    if (isFunc(onSuccess)) onSuccess();
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const createList = payload => ({ type: actionTypes.CREATE_LIST, payload });
export const getList = payload => ({ type: actionTypes.GET_LIST, payload });
export const getListWithRecipes = payload => ({ type: actionTypes.GET_LIST_WITH_RECIPES, payload });
export const getLists = payload => ({ type: actionTypes.GET_LISTS, payload });
export const updateList = payload => ({ type: actionTypes.UPDATE_LIST, payload });
export const deleteList = payload => ({ type: actionTypes.DELETE_LIST, payload });
export const getCategories = payload => ({ type: actionTypes.GET_CATEGORIES, payload });
export const getSuggestedItems = payload => ({ type: actionTypes.GET_SUGGESTED_ITEMS, payload });
export const getSuggestedItemsForArray = payload => ({ type: actionTypes.GET_SUGGESTED_ITEMS_FOR_ARRAY, payload });
export const getSuggestedUnits = payload => ({ type: actionTypes.GET_SUGGESTED_UNITS, payload });
export const addItemToList = payload => ({ type: actionTypes.ADD_ITEM_TO_LIST, payload });
export const removeItemFromList = payload => ({ type: actionTypes.REMOVE_ITEM_FROM_LIST, payload });
export const updateItemInList = payload => ({ type: actionTypes.UPDATE_ITEM_IN_LIST, payload });
export const restoreItemsInList = payload => ({ type: actionTypes.RESTORE_ITEMS_IN_LIST, payload });
export const shareList = payload => ({ type: actionTypes.SHARE_LIST, payload });
export const acceptList = payload => ({ type: actionTypes.ACCEPT_LIST, payload });
export const declineList = payload => ({ type: actionTypes.DECLINE_LIST, payload });
export const findUSDAProducts = payload => ({ type: actionTypes.FIND_USDA_PRODUCTS, payload });
export const getUSDAProduct = payload => ({ type: actionTypes.GET_USDA_PRODUCT, payload });
export const addSuggestedItem = payload => ({ type: actionTypes.ADD_SUGGESTED_ITEM, payload });
export const getCollaborators = payload => ({ type: actionTypes.GET_COLLABORATORS, payload });
export const parseIngredients = payload => ({ type: actionTypes.PARSE_INGREDIENTS, payload });
export const parseIngredientsWithDebounce = payload => ({ type: actionTypes.PARSE_INGREDIENTS_WITH_DEBOUNCE, payload });
export const getFavoriteItems = payload => ({ type: actionTypes.GET_FAVORITE_ITEMS, payload });
export const addFavoriteItems = payload => ({ type: actionTypes.ADD_FAVORITE_ITEMS, payload });
export const removeFavoriteItems = payload => ({ type: actionTypes.REMOVE_FAVORITE_ITEMS, payload });

export default [
  takeLatest(actionTypes.CREATE_LIST, sagaCreateList),
  takeLatest(actionTypes.GET_LIST, sagaGetList),
  takeLatest(actionTypes.GET_LIST_WITH_RECIPES, sagaGetListWithRecipes),
  takeLatest(actionTypes.GET_LISTS, sagaGetLists),
  takeLatest(actionTypes.DELETE_LIST, sagaDeleteList),
  takeLatest(actionTypes.UPDATE_LIST, sagaUpdateList),
  takeLatest(actionTypes.GET_CATEGORIES, sagaGetCategories),
  takeEvery(actionTypes.GET_SUGGESTED_ITEMS, sagaGetSuggestedItems),
  debounce(400, actionTypes.GET_SUGGESTED_UNITS, sagaGetSuggestedUnits),
  takeLatest(actionTypes.GET_SUGGESTED_ITEMS_FOR_ARRAY, sagaGetSuggestedItemsForArray),
  takeEvery(actionTypes.ADD_ITEM_TO_LIST, sagaAddItemToList),
  takeEvery(actionTypes.REMOVE_ITEM_FROM_LIST, sagaRemoveItemFromList),
  takeLatest(actionTypes.UPDATE_ITEM_IN_LIST, sagaUpdateItemInList),
  takeLatest(actionTypes.RESTORE_ITEMS_IN_LIST, sagaRestoreItemsInList),
  takeLatest(actionTypes.SHARE_LIST, sagaShareList),
  takeEvery(actionTypes.ACCEPT_LIST, sagaAcceptList),
  takeEvery(actionTypes.DECLINE_LIST, sagaDeclineList),
  takeLeading(actionTypes.FIND_USDA_PRODUCTS, sagaFindUSDAProducts),
  takeLeading(actionTypes.GET_USDA_PRODUCT, sagaGetUSDAProduct),
  takeEvery(actionTypes.ADD_SUGGESTED_ITEM, sagaAddSuggestedItem),
  debounce(600, actionTypes.GET_COLLABORATORS, sagaGetCollaborators),
  debounce(500, actionTypes.PARSE_INGREDIENTS_WITH_DEBOUNCE, sagaParseIngredientsDebounce),
  takeEvery(actionTypes.PARSE_INGREDIENTS, sagaParseIngredients),
  takeLatest(actionTypes.GET_FAVORITE_ITEMS, sagaGetFavoriteItems),
  takeEvery(actionTypes.ADD_FAVORITE_ITEMS, sagaAddFavoriteItems),
  takeEvery(actionTypes.REMOVE_FAVORITE_ITEMS, sagaRemoveFavoriteItems)
];

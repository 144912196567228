const defaultState = {
  activeList: undefined,
  availableLists: undefined,
  sharedLists: undefined,
  categories: undefined,
  favoriteItems: undefined,
  collaborators: [],
  updateQueue: []
};

export const actionTypes = {
  ACTIVE_LIST: 'ACTIVE_LIST',
  AVAILABLE_LISTS: 'AVAILABLE_LISTS',
  SHARED_LISTS: 'SHARED_LISTS',
  CATEGORIES: 'CATEGORIES',
  FAVORITE_ITEMS: 'FAVORITE_ITEMS',
  COLLABORATORS: 'COLLABORATORS',
  PUSH_TO_UPDATE_QUEUE: 'PUSH_TO_UPDATE_QUEUE',
  PULL_FROM_UPDATE_QUEUE: 'PULL_FROM_UPDATE_QUEUE'
};

export default function (state = defaultState, { type, payload }) {
  let updatedQueue;

  switch (type) {
    case actionTypes.ACTIVE_LIST:
      return {
        ...state,
        activeList: payload
      };
    case actionTypes.AVAILABLE_LISTS:
      return {
        ...state,
        availableLists: payload
      };
    case actionTypes.SHARED_LISTS:
      return {
        ...state,
        sharedLists: payload
      };
    case actionTypes.CATEGORIES:
      return {
        ...state,
        categories: payload
      };
    case actionTypes.FAVORITE_ITEMS:
      return {
        ...state,
        favoriteItems: payload
      };
    case actionTypes.COLLABORATORS:
      return {
        ...state,
        collaborators: payload
      };
    case actionTypes.PUSH_TO_UPDATE_QUEUE:
      updatedQueue = [...state.updateQueue, payload];
      return {
        ...state,
        updateQueue: updatedQueue
      };
    case actionTypes.PULL_FROM_UPDATE_QUEUE:
      updatedQueue = state.updateQueue;
      return {
        ...state,
        updateQueue: updatedQueue.length ? updatedQueue.slice(1) : updatedQueue
      };
    default:
      return state;
  }
}

export const setActiveList = (payload) => ({
  type: actionTypes.ACTIVE_LIST,
  payload
});
export const setAvailableLists = (payload) => ({
  type: actionTypes.AVAILABLE_LISTS,
  payload
});
export const setSharedLists = (payload) => ({
  type: actionTypes.SHARED_LISTS,
  payload
});
export const pullFromUpdateQueue = (payload) => ({
  type: actionTypes.PULL_FROM_UPDATE_QUEUE,
  payload
});
export const setFavoriteItems = (payload) => ({
  type: actionTypes.FAVORITE_ITEMS,
  payload
});

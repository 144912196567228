import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Row } from 'antd';

import styles from './styles.module.scss';
import './styles.global.scss';
import {
  getSuggestedItems,
  addItemToList,
  updateItemInList,
  findUSDAProducts,
  getUSDAProduct,
  addSuggestedItem,
  parseIngredients,
  parseIngredientsWithDebounce
} from '../../sagas/listsSagas';
import { Button, Icon } from '../../../common/components';
import { withDimensions } from '../../../common/HOC';
import { addCategoryForUnknownItem } from '../../../../utils/addCategoryForUnknownItem';
import { getSearchRegExp, doSuggestionsSorting } from '../../helpers';

const { Option } = AutoComplete;

const ItemCreator = (props) => {
  const {
    creating,
    setCreating,
    isAvailable,
    activeList,
    getSuggestedItems,
    addItemToList,
    findUSDAProducts,
    getUSDAProduct,
    addSuggestedItem,
    parseIngredients,
    parseIngredientsWithDebounce,
    favoriteItems
  } = props;
  const { t } = useTranslation('lists');

  const [suggestedItems, setSuggestedItems] = useState([]);
  const [currentParseResult, setCurrentParseResult] = useState(undefined);
  const [newItemDescription, setNewItemDescription] = useState('');
  const [enterDelay, setEnterDelay] = useState(undefined);
  const [suggestionMode, setSuggestionMode] = useState('default');//default|favorite
  const itemCreatorRef = useRef(null);
  let currentlySelectedItem;

  useEffect(() => {
    return () => {
      if (enterDelay) clearTimeout(enterDelay);
    };
  }); //eslint-disable-line

  useEffect(() => {
    if (!favoriteItems?.length) setSuggestionMode('default');
  }, [favoriteItems]); //eslint-disable-line

  useEffect(() => {
    if (itemCreatorRef?.current) {
      itemCreatorRef.current.querySelector('input').blur();
      //itemCreatorRef.current.querySelector('input').focus();
    }
  }, [suggestionMode]); //eslint-disable-line

  const onNewNameFocus = (e) => {
    onNewNameChange(e.target.value || '');
  };

  const onNewNameChange = (value) => {
    setNewItemDescription(value);
    setSuggestedItems([]);
    parseIngredientsWithDebounce({
      ingredients: [value],
      onSuccess: (ingredients) => {
        const result = ingredients?.[0] ?? '';
        setCurrentParseResult(result);
        const parsedName = result.name ?? '';

        const prepareSuggestedItems = (items) => {
          const exactMatch = items.find((item) => item.name.toLowerCase() === parsedName.toLowerCase());
          if (exactMatch) {
            const others = items.filter((item) => item._id !== exactMatch._id);
            setSuggestedItems([exactMatch, ...others]);
          } else if (suggestionMode === 'default') {
            const brandNew = { name: parsedName.trim(), isNew: true };
            setSuggestedItems([brandNew, ...items]);
          } else {
            setSuggestedItems(items);
          }
        };

        if (parsedName.trim().length && suggestionMode === 'default') {
          getSuggestedItems({
            query: parsedName,
            onSuccess: (data) => {
              prepareSuggestedItems(data);
            }
          });
        } else if (suggestionMode === 'favorite') {
          const searchRegExp = getSearchRegExp(parsedName);
          const matchedFavoriteItems = doSuggestionsSorting(favoriteItems.filter((fItem) => searchRegExp.test(fItem.name)), parsedName);
          prepareSuggestedItems(matchedFavoriteItems);
        } else {
          setSuggestedItems([]);
        }
      }
    });
  };

  const onNewNameKeyDown = (e) => {
    if (e.keyCode === 27) {
      setSuggestedItems([]);
      setNewItemDescription('');
    }

    if (e.keyCode === 13) {
      if (!suggestedItems.length && suggestionMode === 'default') {
        processCurrentDescription();
      } else {
        if (enterDelay) clearTimeout(enterDelay);
        setEnterDelay(setTimeout(() => {
          if (!creating && suggestedItems.length && !currentlySelectedItem) {
            createNewItem(suggestedItems[0].name, suggestedItems[0].category, suggestedItems[0].isNew);
          }
        }, 100));
      }
    }
  };

  const processCurrentDescription = () => {
    const currentDescription = newItemDescription;
    setCreating(true);
    parseIngredients({
      ingredients: [currentDescription],
      onSuccess: (ingredients) => {
        const result = ingredients?.[0] ?? '';
        setCurrentParseResult(result);
        const parsedName = result.name ?? '';

        getSuggestedItems({
          query: parsedName,
          onSuccess: (data) => {
            const exactMatch = data.find((item) => item.name.toLowerCase() === parsedName.toLowerCase());
            if (exactMatch) {
              createNewItem(exactMatch.name, exactMatch.category, false, result);
            } else if (parsedName.trim().length) {
              createNewItem(parsedName.trim(), null, true, result);
            } else {
              setCreating(false);
            }
          },
          onError: () => setCreating(false)
        });
      }
    });
  };

  const onNewItemSelected = (value) => {
    //console.log('New item selected:', value);
    currentlySelectedItem = value;
    setTimeout(() => currentlySelectedItem = undefined, 250);
    const selectedItem = suggestedItems.find((item) => item.name === value);
    if (selectedItem?.name) {
      if (!creating) createNewItem(selectedItem.name, selectedItem.category, selectedItem.isNew);
    } else {
      setCreating(false);
    }
  };

  const createNewItem = (name, category, isNew, parseResult) => {
    setCreating(true);
    const newItem = parseResult ?? currentParseResult;
    newItem.isNew = isNew;
    newItem.name = name;
    if (category) newItem.category = category;
    newItem.originalString = newItemDescription;

    const proceed = (itemData) => {
      const options = {
        listId: activeList._id,
        itemData,
        onSuccess: () => {
          setCreating(false);
          setNewItemDescription('');
          setSuggestedItems([]);
          setCurrentParseResult(undefined);
          if (itemCreatorRef?.current) itemCreatorRef.current.querySelector('input').focus();
        },
        onError: () => {
          setCreating(false);
          if (itemCreatorRef?.current) itemCreatorRef.current.querySelector('input').focus();
        }
      };
      addItemToList(options);
    };

    //Adding new items to suggested items list
    if (!!newItem.isNew && newItem.name) {
      addCategoryForUnknownItem(newItem, proceed, findUSDAProducts, getUSDAProduct, addSuggestedItem);
    } else {
      proceed(newItem);
    }
  };

  return (
    <div className={`${styles.itemCreatorWrapper} ItemCreatorWrapper`} ref={itemCreatorRef}>
      <AutoComplete
        className={styles.input}
        onSearch={onNewNameChange}
        onFocus={onNewNameFocus}
        placeholder={t('addNewItemPlaceholder')}
        onSelect={onNewItemSelected}
        onInputKeyDown={onNewNameKeyDown}
        value={newItemDescription}
        disabled={creating || !isAvailable}
      >
        {suggestedItems.map((item) => (
          <Option key={item.name} value={item.name}>
            {currentParseResult.amount ? `${currentParseResult.amount} ` : ''}
            {currentParseResult?.unit
              ? `${currentParseResult?.amount > 1
                ? (currentParseResult.unit?.pluralName ?? currentParseResult.unit?.name)
                : currentParseResult.unit?.name} `
              : ''
            }
            {item.name}
          </Option>
        ))}
      </AutoComplete>
      <Row type="flex" justify="space-around" align="middle" className={styles.controlsWrapper} >
        {/*<Button type="icon" onClick={processCurrentDescription} disabled={creating || !isAvailable} >
          <Icon name="golden-check" />
        </Button>*/}
        <Button
          type="icon"
          onClick={() => setSuggestionMode(suggestionMode === 'default' ? 'favorite' : 'default')}
          className={`${styles.suggestionModeButton} ${!favoriteItems?.length ? styles.hidden : ''}`}
        >
          <Icon name={suggestionMode === 'favorite' ? 'heart-active' : 'heart'} height={20} />
        </Button>
        <Button type="icon" onClick={() => { setNewItemDescription(''); }} disabled={creating} >
          <Icon name="close" />
        </Button>
      </Row>
    </div>


  );
};

ItemCreator.propTypes = {
  item: PropTypes.object,
  creating: PropTypes.bool,
  setCreating: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool,
  activeList: PropTypes.object,
  getSuggestedItems: PropTypes.func.isRequired,
  addItemToList: PropTypes.func.isRequired,
  updateItemInList: PropTypes.func.isRequired,
  findUSDAProducts: PropTypes.func.isRequired,
  getUSDAProduct: PropTypes.func.isRequired,
  addSuggestedItem: PropTypes.func.isRequired,
  parseIngredients: PropTypes.func.isRequired,
  parseIngredientsWithDebounce: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ lists: { activeList, favoriteItems } }) => ({
      activeList, favoriteItems
    }),
    {
      getSuggestedItems,
      addItemToList,
      updateItemInList,
      findUSDAProducts,
      getUSDAProduct,
      addSuggestedItem,
      parseIngredients,
      parseIngredientsWithDebounce
    }
  ),
  withDimensions
)(ItemCreator);

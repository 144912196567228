const escapeRegExp = (text) => {
  return text?.length ? text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') : '';
};

export const getSearchRegExp = (text) => {
  return new RegExp(escapeRegExp(text), 'i');
};

export const getStartRegExp = (text) => {
  return new RegExp(`^${escapeRegExp(text)}`, 'i');
};

export const getFullRegExp = (text) => {
  return new RegExp(`^${escapeRegExp(text)}$`, 'i');
};

export const doSuggestionsSorting = (items, query = '') => {
  const startRegExp = getStartRegExp(query);
  return items.sort((a, b) => {
    let result = 0;
    result += !startRegExp.test(a.name) && startRegExp.test(b.name) ? 10
      : (startRegExp.test(a.name) && !startRegExp.test(b.name) ? -10 : 0);
    result += a.name.localeCompare(b.name);

    return result;
  });
};
